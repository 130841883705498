import ReactGA from "react-ga";
import "./App.css";

import Navbar from "./components/NavBar";
import Hero from "./components/Hero";
import Products from "./components/Products";
import Footer from "./components/Footer";

ReactGA.initialize("UA-63840607-3");

ReactGA.pageview(window.location.pathname + window.location.search);

function App() {
  return (
    <div>
      {/* Header */}
      <Navbar />

      {/* Hero */}
      <Hero />

      {/* Product List */}
      <Products />

      <Footer />
    </div>
  );
}

export default App;
