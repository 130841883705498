import React, { useState } from "react";

import nappyHeadClub from "../../../assets/nappyHeadClub.jpg";
import secretScents from "../../../assets/secretScents.jpg";
import sewLight from "../../../assets/sewLight.jpg";
import flavaSpices from "../../../assets/flavaSpices.png";
import beatitudes from "../../../assets/beatitudes.jpg";
import ncmprbl from "../../../assets/ncmprbl.jpg";
import kwhilbeads from "../../../assets/kwhilbeads.jpg";
import lunaPearl from "../../../assets/lunaPearl.png";
import bEclectic from "../../../assets/bEclectic.jpg";
import cashmere from "../../../assets/cashmere.jpg";
import conscious from "../../../assets/conscious.jpg";
import queenIrie from "../../../assets/queenIrie.jpg";
import waxWonder from "../../../assets/waxWonder.jpg";
import melabebe from "../../../assets/melabebe.jpg";
import bgv from "../../../assets/bgv.png";
import sensational from "../../../assets/sensational.jpg";
import yeaux from "../../../assets/yeaux.jpg";
import myBeautiful from "../../../assets/myBeautiful.png";
import OBWSAdvertise from "../../../assets/OBWSAdvertise.png";
import OBWSApparel from "../../../assets/OBWSApparel.png";
import nurilens from "../../../assets/nurilens.jpg";

import ProductItem from "../ProductItem";

import {
  StyledProductList,
  StyledProductFilter,
  StyledProductsItems,
  StyledFilters,
  StyledButton,
} from "./productlist.styled";

const categories = [
  "all",
  "for him",
  "for her",
  "for kids",
  "apparel & accessories",
  "business services",
  "food & drinks",
  "home goods",
  "personal care",
  "wellness",
];

const productList = [
  {
    brandName: "OBWS Apparel",
    category: "Apparel & Accessories",
    filters: ["apparel & accessories", "all", "for him", "for her"],
    website: "https://shop.obws.com/",
    image: { OBWSApparel },
    id: 1,
  },

  {
    brandName: "Nappy Head Club",
    category: "Apparel & Accessories",
    filters: ["apparel & accessories", "for her", "for him"],
    website: "https://nappyheadclub.com/",
    image: { nappyHeadClub },
    id: 2,
  },
  {
    brandName: "Secret Scents of Ella",
    category: "Home Goods",
    filters: ["home goods"],
    website: "https://www.secretscentsofella.com",
    image: { secretScents },
    id: 3,
  },
  {
    brandName: "Sew Light LLC",
    category: "Wellness",
    filters: ["wellness"],
    website: "https://witheasebook.com",
    image: { sewLight },
    id: 4,
  },
  {
    brandName: "OBWS Advertising",
    category: "Business Services",
    filters: ["Business Services", "all"],
    website: "https://obws.com/advertise/",
    image: { OBWSAdvertise },
    id: 5,
  },

  {
    brandName: "Dr. Flava Spices",
    category: "Food & Drinks",
    filters: ["food & drinks"],
    website: "https://www.drflavaspices.com/",
    image: { flavaSpices },
    id: 6,
  },
  {
    brandName: "Your Beatitudes",
    category: "Apparel & Accessories",
    filters: ["apparel & accessories", "for her"],
    website: "https://yourbeatitudes.com",
    image: { beatitudes },
    id: 7,
  },
  {
    brandName: "NCMPRBL",
    category: "Apparel & Accessories",
    filters: ["apparel & accessories"],
    website: "https://ncmprblwatches.com/",
    image: { ncmprbl },
    id: 8,
  },
  {
    brandName: "Kwhilbeads",
    category: "Apparel & Accessories",
    filters: ["apparel & accessories"],
    website: "https://www.kwhilbeads.com",
    image: { kwhilbeads },
    id: 9,
  },
  {
    brandName: "Luna Pearl",
    category: "Business Service",
    filters: ["business service"],
    website: "https://lunapearl.biz",
    image: { lunaPearl },
    id: 10,
  },
  {
    brandName: "B.Eclectic Brand",
    category: "Apparel & Accessories",
    filters: ["apparel & accessories"],
    website: "https://www.beclecticbrand.com/",
    image: { bEclectic },
    id: 11,
  },
  {
    brandName: "Cashmere Moon",
    category: "Personal Care",
    filters: ["personal care"],
    website: "https://shopcashmeremoon.com",
    image: { cashmere },
    id: 12,
  },
  {
    brandName: "Conscious Loose Teas",
    category: "Wellness",
    filters: ["wellness"],
    website: "https://consciouslooseteas.com",
    image: { conscious },
    id: 13,
  },
  {
    brandName: "Queen Irie Designs Inc",
    category: "Apparel & Accessories",
    filters: ["apparel & accessories"],
    website: "https://www.queenirie.online",
    image: { queenIrie },
    id: 14,
  },
  {
    brandName: "Wax & Wonder by Afropolitan",
    category: "Apparel & Accessories",
    filters: ["apparel & accessories"],
    website: "https://waxandwonder.com/",
    image: { waxWonder },
    id: 15,
  },
  {
    brandName: "Melabebe",
    category: "For Kids",
    filters: ["for kids", "personal care"],
    website: "https://www.melabebe.com/",
    image: { melabebe },
    id: 16,
  },
  {
    brandName: "BGV Collective",
    category: "Apparel & Accessories",
    filters: ["apparel & accessories", "for her"],
    website: "https://bgvcollective.com/",
    image: { bgv },
    id: 17,
  },
  {
    brandName: "Sensational Kreations",
    category: "Apparel & Accessories",
    filters: ["apparel & accessories", "for her"],
    website: "https://www.sensationalkreations.com",
    image: { sensational },
    id: 18,
  },
  {
    brandName: "Yeaux Mama",
    category: "Apparel & Accessories",
    filters: ["apparel & accessories", "for her", "for him"],
    website: "https://yeauxmama.com",
    image: { yeaux },
    id: 19,
  },
  {
    brandName: "My Beautiful Fluff",
    category: "Apparel & Accessories",
    filters: ["apparel & accessories", "for her"],
    website: "https://www.mybeautifulfluff.com",
    image: { myBeautiful },
    id: 20,
  },
  {
    brandName: "NURILENS",
    category: "Apparel & Accessories",
    filters: ["apparel & accessories", "all", "for him", "for her"],
    website: "https://nurilens.co/",
    image: { nurilens },
    id: 21,
  },
];

const Button = ({ category, active, setActive }) => {
  return (
    <StyledButton active={active} onClick={() => setActive(category)}>
      {category}
    </StyledButton>
  );
};

const ProductList = () => {
  const [active, setActive] = useState("all");
  const [openFilters, setOpenFilters] = useState(false);
  const [activeCategory, setActiveCategory] = useState(productList);

  const setOpenFiltersHandler = () => {
    setOpenFilters((prevState) => {
      return !prevState;
    });
  };

  const setActiveHandler = (category) => {
    setActive(category);

    // Filter category
    if (category === "all") {
      setActiveCategory(productList);
    } else {
      let newList = productList.filter((product) => {
        if (product.filters.includes(category)) {
          return true;
        } else {
          return false;
        }

        // product.filters.map(filter => {
        //   return filter.toLowerCase() === category
        // })
      });
      setActiveCategory(newList);
    }
  };

  return (
    <StyledProductList>
      <StyledProductFilter>
        <div>
          <button className="filter" onClick={setOpenFiltersHandler}>
            Filter
          </button>
        </div>

        <StyledFilters openFilters={openFilters}>
          {categories.map((category) => {
            return (
              <Button
                key={category}
                category={category}
                active={active === category}
                setActive={setActiveHandler}
              />
            );
          })}
        </StyledFilters>
      </StyledProductFilter>
      <StyledProductsItems>
        {activeCategory.map((product) => {
          return (
            <ProductItem
              brandName={product.brandName}
              category={product.category}
              image={product.image}
              website={product.website}
              key={product.id}
            />
          );
        })}
      </StyledProductsItems>
    </StyledProductList>
  );
};

export default ProductList;
