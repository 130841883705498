import React from 'react'
import styled from 'styled-components'

import Container from '../Container'

const StyledFooter = styled.footer`
  text-align: center;
  background-color: #fff;
  color: #000;
`

const Footer = () => {
  return (
    <StyledFooter>
      <Container>
        <p>
          Copyright © 2020 Official Black Wall Street | All Rights Reserved
          Terms & Conditions
        </p>
      </Container>
    </StyledFooter>
  )
}

export default Footer
