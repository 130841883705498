import styled from 'styled-components'

export const StyledProductItem = styled.div`
  width: 24%;
  min-width: 280px;
  margin-bottom: 40px;
  position: relative;

  &:hover {
    div.hovered {
      display: block;
      width: 100%;
      height: 300px;
      position: absolute;
      top: 0;
      background: rgba(255, 110, 38, 0.7);
      display: flex;
      justify-content: center;

      a {
        border: none;
        border-radius: 20px;
        background-color: #fff;
        color: #ff6e26;
        text-transform: uppercase;
        font-size: 0.8rem;
        padding: 10px 50px;
        font-weight: 600;
        cursor: pointer;
        text-decoration: none;
      }
    }
  }

  img {
    width: 100%;
    height: 300px;
    object-fit: cover;
  }

  div {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h3 {
      font-size: 1rem;
      letter-spacing: 0.46px;
      font-weight: 600;
    }

    p {
      text-transform: uppercase;
      letter-spacing: 0.36px;
      color: #ffc5a2;
      font-size: 0.8rem;
    }

    &.hovered {
      display: none;
    }
  }

  @media screen and (max-width: 480px) {
    width: 100%;
  }
`
