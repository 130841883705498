import styled from 'styled-components'

export const StyledTopNav = styled.div`
  display: flex;
  align-items: center;
  height: 58px;
  background: #000000;

  @media screen and (max-width: 880px) {
    height: fit-content;
    display: ${props => (props.showMobile ? 'block' : 'none')};

    img {
      display: none;
    }
  }
`
export const StyledTopNavInner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;

  a {
    color: #fff;
    text-decoration: none;
    display: inline-block;
    margin: 0 1rem;
  }

  img {
    height: 40px;
  }

  @media screen and (max-width: 880px) {
    a {
      display: block;
      margin: 1rem 0;
    }
  }
`

export const StyledBottomNav = styled.div`
  display: flex;
  align-items: center;
  height: 53px;
  box-shadow: 0px 3px 30px #0000004f;

  @media screen and (max-width: 880px) {
    height: fit-content;
    display: 'block';
    padding-bottom: 1rem;
    background-color: #000;
    display: ${props => (props.showMobile ? 'block' : 'none')};
  }
`

export const StyledBottomNavInner = styled.div`
  display: flex;
  justify-content: center;

  a {
    display: inline-block;
    margin: 0 1rem;
    color: #000;
    text-decoration: none;
  }

  @media screen and (max-width: 880px) {
    display: block;

    a {
      display: block;
      margin: 0 0 1rem 0;
      color: #fff;
    }
  }
`

export const StyledMobileNav = styled.div`
  height: 80px;
  background: #000;
  color: #fff;
  display: flex;
  align-items: center;
  /* position: fixed; */
  width: 100%;

  img {
    height: 50px;
  }

  a {
    color: #fff;
  }

  @media screen and (min-width: 881px) {
    display: none;
  }
`

export const StyledMobileNavInner = styled.div`
  height: 100;
  display: flex;
  justify-content: space-between;
`
