import React from "react";

import { StyledProductItem } from "./productitem.styled";

import bread from "../../../assets/bread.jpg";

const ProductItem = ({ brandName, website, category, image }) => {
  return (
    <StyledProductItem>
      <div>
        <img src={image[Object.keys(image)[0]]} alt={brandName} />
        <div className="hovered">
          <a href={website ? website : "#!"} target="_blank" rel="noreferrer">
            Shop Now
          </a>
        </div>
      </div>
      <div>
        <h3>{brandName ? brandName : "Name Product"}</h3>
        <p>{category ? category : "Category"}</p>
      </div>
    </StyledProductItem>
  );
};

export default ProductItem;
