import React from 'react'

import Container from '../Container'
import ProductList from './ProductList'

import { StyledProducts, StyledProductsHeader } from './products.styled'

const Products = () => {
  return (
    <StyledProducts>
      <Container>
        <StyledProductsHeader>
          <h1>Shop</h1>
          <div>
            <h5>About</h5>
            <p>
              Tis the season! The spirit of giving is here, and we're
              celebrating the holiday season with a curated marketplace filled
              with amazing Black-owned businesses. Whether you're shopping for
              family, friends, or even yourself, you'll find something for all
              your gift-giving needs right here.
            </p>
          </div>
        </StyledProductsHeader>
        <ProductList />
      </Container>
    </StyledProducts>
  )
}

export default Products
