import React, { Fragment, useState } from 'react'
import logo from '../../assets/obws_logo.png'
import bars from '../../assets/bars.svg'

import {
  StyledBottomNav,
  StyledBottomNavInner,
  StyledMobileNav,
  StyledMobileNavInner,
  StyledTopNav,
  StyledTopNavInner
} from './navbar.styled'

// Custom Imports
import Container from '../Container'

// Assets
// import logo from '../assets/'

const Navbar = () => {
  const [openNav, setOpenNav] = useState(false)

  const openNavHandler = () => {
    setOpenNav(prevState => {
      return !prevState
    })
  }

  return (
    <Fragment>
      {/* Mobile Nav */}
      <StyledMobileNav>
        <Container>
          <StyledMobileNavInner>
            <img src={logo} alt='Logo' />
            <a href='#!' onClick={openNavHandler}>
              <img src={bars} alt='bars' width='28px' height='20px' />
            </a>
          </StyledMobileNavInner>
        </Container>
      </StyledMobileNav>

      {/* Top Nav */}
      <StyledTopNav showMobile={openNav}>
        <Container>
          <StyledTopNavInner>
            <img src={logo} alt='Logo' />
            <div>
              <a href='https://obws.com/login/' target='__blank'>
                Log In
              </a>
              <a href='https://obws.com/signup/' target='__blank'>
                Sign Up
              </a>
            </div>
          </StyledTopNavInner>
        </Container>
      </StyledTopNav>

      {/* Bottom Nav */}
      <StyledBottomNav showMobile={openNav}>
        <Container>
          <StyledBottomNavInner>
            <a href='https://obws.com/about-us/' target='__blank'>
              About Us
            </a>
            <a href='https://shop.obws.com/' target='__blank'>
              Shop
            </a>
            <a href='https://blog.obws.com/' target='__blank'>
              Features
            </a>
            <a href='https://obws.com/advertise/' target='__blank'>
              Advertise
            </a>
            <a href='https://obws.com/search/business/' target='__blank'>
              Businesses
            </a>

            <a
              href='https://obws.com/join/'
              target='__blank'
              style={{ color: '#F15A24' }}
            >
              List Your Business
            </a>
          </StyledBottomNavInner>
        </Container>
      </StyledBottomNav>
    </Fragment>
  )
}

export default Navbar
