import React from 'react'

import { StyledHero, StyledHeroContent } from './hero.styled'

import logo from '../../assets/obws_logo.png'

const index = () => {
  return (
    <StyledHero>
      <StyledHeroContent>
        <img src={logo} alt='Obws Logo' />
        <h3>Black (owned)</h3>
        <h1>Holiday</h1>
        <h2>Marketplace</h2>
        <h4>#OBWSHoliday</h4>
        {/* <a href='#!'>GET YOUR FREE LIVE SHOWCASE TICKET</a> */}
      </StyledHeroContent>
    </StyledHero>
  )
}

export default index
