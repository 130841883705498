import styled from 'styled-components'

import bg from '../../assets/produclist-bg.svg'

export const StyledProducts = styled.section`
  
  background: url(${bg}) #000808;
  background-size: cover;
  color: #fff;
  padding-top: 1rem;
  padding-bottom: 3rem;
  margin-bottom: 0;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: -140px;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 300px;
    background: #000808;
    transform: skewY(-3deg);
    transform-origin: right;
  }

  @media screen and (max-width: 480px) {
    &::before {
      height: 700px;
      z-index: -1;
    }
`
export const StyledProductsHeader = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  h1 {
    font-family: flowers;
    font-size: 8rem;
    font-weight: 400;
    margin-top: 5rem;
    margin-bottom: 0;
    z-index: 200;
  }

  div {
    width: 800px;
    text-align: right;
    z-index: 200;
  }

  h5 {
    font-weight: 400;
    color: #ff6e26;
    margin-bottom: 0;
    z-index: 200;
    font-size: 1.5rem;
  }

  @media screen and (max-width: 480px) {
    flex-direction: column-reverse;

    h1 {
      text-align: center;
    }

    div {
      text-align: center;
      width: 100%;
      margin-top: 3rem;
    }

    h5 {
      font-size: 1.2rem;
    }
  }
`
