import styled from 'styled-components'

export const StyledProductList = styled.div``

export const StyledProductFilter = styled.div`
  margin-top: 0;
  display: flex;
  flex-direction: column;
  justify-content: end;
  margin-bottom: 3rem;

  button {
    &.filter {
      background: #ffffff;
      border: none;
      border-radius: 30px;
      text-transform: uppercase;
      font-weight: 700;
      padding: 8px 60px;
      margin-bottom: 2rem;
      display: inline;
      font-size: 16px;
      float: right;
    }
  }

  @media screen and (max-width: 480px) {
    justify-content: center;
    text-align: center;

    button {
      &.filter {
        margin-top: 3rem;
        margin-bottom: 0.8rem;
        float: none;
        padding: 6px 40px;
        font-size: 12px;
        font-weight: semi-bold;
      }
    }
  }
`

export const StyledProductsItems = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
`

export const StyledFilters = styled.div`
  display: ${props => (props.openFilters ? 'flex' : 'none')};
  flex-wrap: wrap;
  margin: auto;
  text-align: center;
  margin-top: 2rem;
`

export const StyledButton = styled.button`
  background: ${props => (props.active ? '#FF6E26' : 'transparent')};
  color: rgba(255, 255, 255, 0.85);
  padding: 8px 50px;
  text-transform: uppercase;
  margin: 10px 10px;
  border-radius: 19px;
  border: ${props => (props.active ? 'none' : '1px solid #ffffff')};
  font-size: 14px;
  font-weight: ${props => props.active && '600'};
  cursor: pointer;

  @media screen and (max-width: 480px) {
    font-size: 12px;
    padding: 5px 20px;
    margin: 5px;
  }
`
