import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  width: 90%;
  max-width: 90%;
  margin: 0 auto;

  @media screen and (max-width: 480px) {
    width: 80%;
  }
`

const StyledContainer = ({ children }) => {
  return <Container>{children}</Container>
}

export default StyledContainer
