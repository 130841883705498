import styled from 'styled-components'

import heroBg from '../../assets/hero-bg.png'
import heroBgMob from '../../assets/herobg-mob.png'

export const StyledHero = styled.section`
  height: 100vh;
  background: url(${heroBg}) no-repeat;
  background-size: cover;
  background-position: top;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;

  @media screen and (max-width: 480px) {
    background: url(${heroBgMob}) no-repeat;
    background-size: cover;
  }
`

export const StyledHeroContent = styled.div`
  z-index: 100;
  margin-top: -10vh;

  img {
    width: 60px;
  }

  h3 {
    font-size: 3.5rem;
    margin: 0;
    padding: 0;
    font-weight: 500;
  }

  h1 {
    padding: 0;
    margin: -50px;
    margin-bottom: 0;
    font-size: 7rem;
    text-transform: uppercase;
    color: #ff6e26;
    font-weight: 600;
  }

  h2 {
    font-family: flowers;
    font-size: 8rem;
    margin: 0;
    padding: 0;
    margin-top: -80px;
    font-weight: 400;
    letter-spacing: 8px;
  }

  h4 {
    margin-top: 0;
    font-weight: 500;
    letter-spacing: 1px;
    font-size: 1.4rem;
  }

  a {
    color: #ff6e26;
    background-color: #ffc5a2;
    font-weight: 500;
    border-radius: 50px;
    box-sizing: border-box;
    width: fit-content;
    margin: 0 auto;
    padding: 5px 20px;
    font-size: 0.8rem;
    text-decoration: none;
  }

  @media screen and (max-width: 480px) {
    margin-top: initial;

    h1 {
      font-size: 4.2rem;
      margin: -20px;
    }

    h2 {
      font-size: 4rem;
      margin-top: -40px;
    }

    h3 {
      font-size: 2.3rem;
    }
  }
`
